import Collapsible from "react-collapsible";
import { Row, Col } from "reactstrap";
import { publicationsArray } from "../data";
import Links from "./LInks";

function Publications() {
  return (
    <>
      <div id={'publications-section'} className="publication-name">
        <h4 className="cool-link">Publications</h4>
      </div>
      {publicationsArray.map((val) => {
        return (
          <div>
            <div className="publications">
              <div className="publications-container">
                {val.award && <h4 className="paper-award">{val.award}</h4>}
                <h5>{val.name}</h5>
                <div>
                  <span className="author-name-first ">
                    {val.author + ", "}
                  </span>
                  <span className="author-name">
                    {val.subAuthors.join(", ")}
                  </span>
                </div>
                <Row>
                  <Col md={val.titleImage ? 6 : 12}>

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={val.paperLink}
                      className="paper-link"
                    >
                      {val.venueTitle}
                    </a>
                    <Links links={val.links} />
                  </Col>
                  {val.titleImage && <Col md={6}>
                    {val.titleImage}
                  </Col>}
                </Row>
                {val.abstract ? (
                  <div className="abstract">
                    <Collapsible trigger="Abstract" className="collaps">
                      <p>{val.abstract}</p>
                    </Collapsible>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
export default Publications;
