import { Fragment } from "react";
import avatar from "../assets/images/mukhtiar_ahmad.jpg";
function TopBar({ name, email, address }) {
  return (
    <div className="main-container">
      <div className="img-container">
        <img alt="img" className="avatar_img" width={200} src={avatar} />
      </div>

      <div className="content-container">
        <h4 className="cool-link">{name}</h4>
        <div>{[
          { title: 'About', href: "#about-section" },
          { title: 'Publications', href: "#publications-section" },
          { title: 'Experience', href: "#experience-section" }
        ].map((sec, index, arr) => <Fragment>
          <a style={{ padding: '0 5px' }} href={sec.href}>{sec.title}</a>
          {index < arr.length - 1 ? <span>{" | "}</span> : null}
        </Fragment>)}</div>
        <span>{email}</span>
        <span>{address}</span>
      </div>
    </div>
  );
}
export default TopBar;
