import TopBar from "../components/TopBar";
import AboutSections from "./AboutSections";
import Publications from "./Publications";
import { userInfo, aboutInfo, teachingExperience } from "../data";
import Experience from "./Experience";
import { experienceArray } from "../data";

export default function Home() {
  return (
    <>
      <TopBar {...userInfo} />
      <AboutSections {...aboutInfo} />
      <Publications />
      {/* <Experience
        experiences={teachingExperience}
        title={"Teaching Experience"}
      /> */}
      <Experience experiences={experienceArray} title={"Industry Experience"} />
    </>
  );
}
