import sigcomm19 from "./assets/papers/sigcomm19/design.png";
import sigcomm20 from "./assets/papers/sigcomm20/OverAllArchitecture.png";
import conext22 from "./assets/papers/conext22/system_architecture.png";
import ton22 from "./assets/papers/ton22/nested_rings.png";
import wrappingtheedge from "./assets/images/wrappingtheedge.png"

const OverviewImageRenderer = (src) => {
  return <div className="paper-overview">
    <img alt="img" src={src} />
  </div>
}

export const userInfo = {
  name: "Mukhtiar Ahmad",
  email: "18060046@lums.edu.pk | mukhtiar.ahmad@lums.edu.pk",
  // address: "House No. 239-C, Street F75, Phase Nine Town, DHA, Lahore, Pakistan"
  address: "School of Science and Engineering, LUMS, Lahore, Pakistan"
};

export const aboutInfo = {
  paragraphs: [
    "I earned my Ph.D. from LUMS in August 2023, focusing on re-architecting cellular networks to enhance their robustness and enable support for emerging ultra-low-latency communication-based applications. Currently, I am employed as a technical architect at Powersoft19, where I lead a team developing cutting-edge solutions across various domains, including embedded systems, industrial automation, artificial intelligence, safety-critical systems, remote site monitoring, and web applications. My research has been published in leading venues such as ACM SIGCOMM and IEEE Transactions on Networking. I was honored to receive the SBARA Research Award, which recognized me as one of the top five researchers making impactful contributions at LUMS. My broad research interests are in networked and distributed systems and the application of generative AI to solve real-world problems."
  ],
bullets: [
  // "A Scalable and low-latency cellular control plane for latency-sensitive edge applications. This work is published in ACM SIGCOMM’20.",
  // "A low-latency cellular control plane with geo-aware proactive user state replication. This work is published in IEEE/ACM Transactions on Networking.",
  // "A quorum-based distributed cellular control plane to mitigate the issue of slow fault detection. This work is published in ACM CoNEXT’22."
],
  links: [
    {
      name: "CV",
      route:
        "https://drive.google.com/file/d/1vztNKv_AzGOaUuY4gCKCPgKkMCIcy2Je/view?usp=sharing"
    },
    // {
    //   name: "Blog",
    //   route: "https://v5.reactrouter.com/web/guides/quick-start"
    // },
    {
      name: "Google Scholar",
      route:
        "https://scholar.google.com/citations?user=k02inCAAAAAJ&hl=en&oi=srat"
    },
    // {
    //   name: "Github",
    //   route: "https://v5.reactrouter.com/web/guides/quick-start"
    // },
    {
      name: "LinkedIn",
      route: "https://www.linkedin.com/in/mukhtiarahmad/"
    }
  ]
};

export const publicationsArray = [

  {
    // award: "Best Student Paper Award",
    name:
      "Warping the Edge: Where Instant Mobility in 5G Meets Stateful Applications",
    author: ["Mukhtiar Ahmad"],
    subAuthors: [
      "Faaiq Bilal",
      "Mutahar Ali",
      "Muhammad Ali Nawazish,",
      "Amir Salman",
      "Shazer Ali",
      "Fawad Ahmad",
      "Zafar Ayyub Qazi"

    ],
    paperLink: "https://conferences2.sigcomm.org/co-next/2022/#!/home",
    // venueTitle: "ACM CoNEXT'22",
    titleImage: OverviewImageRenderer(wrappingtheedge),
    abstract: "This paper is currently under review in MobiCom 2025",
    links: [
      // {
      //   name: ["Paper PDF"],
      //   route: "https://www.csail.mit.edu/people/"
      // },
      // {
      //   name: "Slides",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      // {
      //   name: "Project Page",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      {
        name: "Code",
        route: "https://github.com/nsgLUMS/cellclone.git"
      }
      // {
      //   name: "Talk",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // }
    ]
  },

  {
    // award: "Best Student Paper Award",
    name:
      "CellClone: Accelerating 5G Applications with Active Control Plane Clones at the Edge.",
    author: ["Mukhtiar Ahmad"],
    subAuthors: [
      "Syed Muhammad Nawazish Ali",
      "Muhammad Taimoor Tariq",
      "Muhammad Ahmed",
      "Muhammad Basit Iqbal Awan",
      "Muhammad Taqi Raza",
      "Zafar Ayyub Qazi"
    ],
    paperLink: "",
    venueTitle: "",
    titleImage: OverviewImageRenderer(conext22),
    abstract:
      "This paper is currently under review in IEEE/ACM Transactions on Networking",
    links: [
      // {
      //   name: ["Paper PDF"],
      //   route: "https://www.csail.mit.edu/people/"
      // },
      // {
      //   name: "Slides",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      // {
      //   name: "Project Page",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      {
        name: "Code",
        route: "https://github.com/nsgLUMS/cellclone.git"
      }
      // {
      //   name: "Talk",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // }
    ]
  },


  {
    // award: "Best Student Paper Award",
    name:
      "Enabling Emerging Edge Applications Through a 5G Control Plane Intervention",
    author: ["Mukhtiar Ahmad"],
    subAuthors: [
      "Syed Muhammad Nawazish Ali",
      "Muhammad Taimoor Tariq",
      "Muhammad Basit Iqbal Awan",
      "Dr. Taqi Raza",
      "Dr. Zafar Ayyub Qazi"
    ],
    paperLink: "https://conferences2.sigcomm.org/co-next/2022/#!/home",
    venueTitle: "ACM CoNEXT'22",
    titleImage: OverviewImageRenderer(conext22),
    abstract:
      "5G networks are considered potential enablers for many emerging edge applications, such as those related to autonomous vehicles, virtual and augmented reality, and online gaming. However, recent works have shown the cellular control plane is a potential bottleneck in enabling such applications — control plane operations are slow, frequent, and can directly impact the delay experienced by end-user applications. Moreover, failures in the cellular control plane can significantly degrade application performance. In this paper, we consider the problem of enabling latency-sensitive and safety-critical edge applications on 5G networks. We identify fundamental control plane design challenges and posit enabling these applications requires re-thinking the cellular control plane. We propose a new edge-based cellular control plane, CellClone, which provides fast and fault-tolerant control plane processing. CellClone employs multiple active control plane clones at the network edge to mask control plane faults and speed up control processing. Central to its design is a custom quorum-based consistency protocol that provides state consistency with low latency. Testbed evaluations using real cellular traces show a median improvement of more than 3.8× in speeding up control plane operations with outright node failures and stragglers. These improvements translate into better application performance; with CellClone, autonomous cars and VR applications reduce missed application deadlines by more than 90%.",
    links: [
      // {
      //   name: ["Paper PDF"],
      //   route: "https://www.csail.mit.edu/people/"
      // },
      // {
      //   name: "Slides",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      // {
      //   name: "Project Page",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      {
        name: "Code",
        route: "https://github.com/nsgLUMS/cellclone.git"
      }
      // {
      //   name: "Talk",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // }
    ]
  },

  {
    award: "",
    name: "Neutrino: A Fast and Consistent Edge-based Cellular Control Plane",
    author: ["Mukhtiar Ahmad"],
    subAuthors: [
      "Syed Muhammad Nawazish Ali",
      "Muhammad Taimoor Tariq",
      "Syed Usman Jafri",
      "Adnan Abbas",
      "Syeda Mashal Abbas Zaidi",
      "Muhammad Basit Iqbal Awan",
      "Zartash Afzal Uzmi",
      "Zafar Ayyub Qazi"
    ],
    paperLink: "https://ieeexplore.ieee.org/abstract/document/9885031",
    venueTitle: "IEEE/ACM Transactions on Networking (2022)",
    titleImage: OverviewImageRenderer(ton22),
    abstract:
      "5G and next-generation cellular networks aim to support tactile internet to enable immersive and real-time applications by providing ultra-low latency and extremely high reliability. This imposes new requirements on the design of cellular core networks. A key component of the cellular core is the control plane. Time to complete cellular control plane operations (e.g., mobility handoff, service establishment) directly impacts the delay experienced by end-user applications. In this paper, we design Neutrino, a cellular control plane that provides users an abstraction of reliable access to cellular services while ensuring lower latency. Our testbed evaluations based on real cellular control traffic traces show that Neutrino provides an improvement in control procedure completion times by up to 3.1× without failures, and up to 5.6× under control plane failures, over existing 5G. We also show how these improvements translate into improving end-user application performance: for AR/VR applications and self-driving cars, Neutrino improves performance by up to 2.5× and 2.8×, respectively.",
    links: [
      // {
      //   name: ["Paper PDF"],
      //   route: "https://www.csail.mit.edu/people/"
      // },
      // {
      //   name: "Slides",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      // {
      //   name: "Project Page",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      {
        name: "Code",
        route: "https://github.com/nsgLUMS/neutrino.git"
      }
      // {
      //   name: "Talk",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // }
    ]
  },

  {
    // award: "Best Student Paper Award",
    name: "A Low Latency and Consistent Cellular Control Plane",
    author: ["Mukhtiar Ahmad"],
    subAuthors: [
      "Syed Muhammad Nawazish Ali",
      "Muhammad Taimoor Tariq",
      "Syed Usman Jafri",
      "Adnan Abbas",
      "Syeda Mashal Abbas Zaidi",
      "Muhammad Basit Iqbal Awan",
      "Zartash Afzal Uzmi",
      "Zafar Ayyub Qazi"
    ],
    paperLink: "https://dl.acm.org/doi/abs/10.1145/3387514.3406218",
    venueTitle: "ACM SIGCOMM'20",
    titleImage: OverviewImageRenderer(sigcomm20),
    abstract:
      "5G networks aim to provide ultra-low latency and higher reliability to support emerging and near real-time applications such as augmented and virtual reality, remote surgery, self-driving cars, and multi-player online gaming. This imposes new requirements on the design of cellular core networks. A key component of the cellular core is the control plane. Time to complete control plane operations (e.g. mobility handoff, service establishment) directly impacts the delay experienced by end-user applications. In this paper, we design Neutrino, a cellular control plane that provides users an abstraction of reliable access to cellular services while ensuring lower latency. Our testbed evaluations based on real cellular control traffic traces show that Neutrino provides an improvement in control procedure completion times by up to 3.1x without failures, and up to 5.6x under control plane failures, over existing cellular core proposals. We also show how these improvements translate into improving end-user application performance: for AR/VR applications and self-driving cars, Neutrino performs up to 2.5x and up to 2.8x better, respectively, as compared to existing EPC.",
    links: [
      {
        name: ["Paper PDF"],
        route: "https://dl.acm.org/doi/pdf/10.1145/3387514.3406218"
      },
      {
        name: "Slides",
        route:
          "https://docs.google.com/presentation/d/1OkqTi07h20QjGww1ZqqwXr853rwi1yo_/edit?usp=sharing&ouid=107426896329999670305&rtpof=true&sd=true"
      },
      // {
      //   name: "Project Page",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      // {
      //   name: "Code",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      {
        name: "Talk",
        route: "https://www.youtube.com/watch?v=ZCzLsMV5n9A"
      }
    ]
  },

  {
    // award: "Best Student Paper Award",
    name: "Fast EPC: A Low Latency Cellular Control Plane",
    author: ["Mukhtiar Ahmad"],

    subAuthors: [
      "Syed Muhammad Nawazish Ali",
      "Muhammad Taimoor Tariq",
      "Syed Usman Jafri",
      "Adnan Abbas",
      "Syeda Mashal Abbas Zaidi",
      "Muhammad Basit Iqbal Awan",
      "Zartash Afzal Uzmi",
      "Zafar Ayyub Qazi"
    ],
    titleImage: OverviewImageRenderer(sigcomm19),
    abstract:
      "Timely completion of control plane operations is critical to providing fast data access in cellular networks. In this work, we design a new edge-based cellular control plane, Fast EPC, which reduces control plane latency through fast serialization of control messages and rapid failure recovery.",
    paperLink: "https://dl.acm.org/doi/abs/10.1145/3342280.3342324",
    venueTitle: "ACM SIGCOMM'19",
    links: [
      {
        name: ["Poster PDF"],
        route: "https://dl.acm.org/doi/10.1145/3342280.3342324"
      }
      // ,
      // {
      //   name: "Slides",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      // {
      //   name: "Project Page",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      // {
      //   name: "Code",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // },
      // {
      //   name: "Talk",
      //   route: "https://v5.reactrouter.com/web/guides/quick-start"
      // }
    ]
  }
  // ,

  // {
  //   award: "Best Student Paper Award",
  //   name: "Finding Safety in Numbers with Secure Allegation Escrows",
  //   authors: ["Venkat Arun", "Mohammad Alizadeh", "Hari Balakrishnan"],
  //   paperLink: "ACM SIGCOMM'22",
  //   links: [
  //     {
  //       name: ["Paper PDF"],
  //       route: "https://www.csail.mit.edu/people/"
  //     },
  //     {
  //       name: "Slides",
  //       route: "https://v5.reactrouter.com/web/guides/quick-start"
  //     },
  //     {
  //       name: "Project Pages",
  //       route: "https://v5.reactrouter.com/web/guides/quick-start"
  //     },
  //     {
  //       name: "Code",
  //       route: "https://v5.reactrouter.com/web/guides/quick-start"
  //     },
  //     {
  //       name: "Talk",
  //       route: "https://v5.reactrouter.com/web/guides/quick-start"
  //     }
  //   ]
  // }
];

// experience data
export const teachingExperience = [
  {
    role: "Lead Teaching Assistant",
    subTitle: "CS-582: Distributed Systems LUMS, Lahore, Pakistan",
    date: "Sep. 2019 – Dec. 2020 & Sep. 2020 – Dec. 2021",
    location: "Lahore, Pakistan",
    bullets: [
      {
        val: "Conducted tutorial sessions"
      },
      { val: "Assistant in assignments design" },
      { val: "Assistant in grading quizzes and assignments" }
    ]
  }
];

export const experienceArray = [
  {
    role: "Technical Architect",
    subTitle: "Powersoft19 Pvt. Ltd.",
    date: "Nov. 2019 – Present",
    location: "Lahore, Pakistan",
    bullets: [
      {
        val:
          "Designed and developed an IoT-based solution for power generator and automatic transfer switch monitoring."
      },
      {
        val:
          "Lead of the design team for remote railway crossing monitoring using diverse sensors."
      },
      {
        val:
          "Designed various applications based on retrieval-augmented generation (RAG)."
      },

      {
        val:
          "Leading a team to design and develop a fermentation device."
      },
      {
        val:
          "Part of the team that designed a blue flag indicator for rail yards."
      },

      {
        val: "Authored four US patents",
        subBullets: [
          "Inventor in three patents",
          "Two patents are under provisional submission"
        ]
      }
    ]
  },
  {
    role: "Technical Team Lead",
    subTitle: "Powersoft19 Pvt. Ltd.",
    date: "Oct. 2017 – Nov. 2019",
    location: "Lahore, Pakistan",
    bullets: [
      {
        val:
          "Designed and developed fixed and portable variants of the roadway workers safety system for different challenging installation environments",
        subBullets: [
          "Customized a background subtraction algorithm for rail-yard environment",
          "Designed an algorithm for train detection using LiDAR, RADAR, IMU and vision sensor",
          "Designed a time-division multiplexing based wireless communication protocol for radios operating in ISM bands"
        ]
      },
      {
        val:
          "Developed a prototype of faulty switch detection based on current consumption patterns",
        subBullets: []
      }
    ]
  },
  {
    role: "Senior Embedded Systems Engineer",
    subTitle: "Powersoft19 Pvt. Ltd.",
    date: "Sep. 2013 – Oct. 2017",
    location: "Lahore, Pakistan",
    bullets: [
      {
        val:
          "Designed and developed a train detection algorithm and wireless communication protocol for roadway workers safety system"
      },
      {
        val:
          "Developed a prototype simulator for train scheduling and infrastructure planning",
        subBullets: []
      }
    ]
  },
  {
    role: "Embedded Systems Engineer",
    subTitle: "Powersoft19 Pvt. Ltd.",
    date: "Dec. 2011 – Sep. 2013",
    location: "Lahore, Pakistan",
    bullets: [
      {
        val:
          "Designed and developed a system for rail-cars tracking in rail-yards with the following key modules:",
        subBullets: [
          "Axle counting with the help of inductive wheel sensors",
          "Cars counting by train coupler detection using a custom pattern recognition algorithm",
          "Car identification using automatic equipment identification tags",
          "Car tracking by using the information reported from different locations in rail-yards"
        ]
      },
      {
        val:
          "Designed and developed a prototype of USB to USB data copier by using a touch panel and single board computer",
        subBullets: []
      }
    ]
  },
  {
    role: "Embedded Systems Developer",
    subTitle: "Powersoft19 Pvt. Ltd.",
    date: "Sep. 2009 – Dec. 2011",
    location: "Lahore, Pakistan",
    bullets: [
      {
        val:
          "Developed a train simulator based on two different mathematical modes",
        subBullets: [
          "Single mass model: assumes the whole train as a single mass. It is based on a patent by John. Mosier.",
          "Distributed mass model: assumes every car within the train consist as a separate mass with a mathematical model for coupler"
        ]
      },
      {
        val:
          "Developed a firmware application for radio remote control for remote train operators",
        subBullets: []
      }
    ]
  }
];
