function Links({ links }) {
  return (
    <>
      <div className="links">
        {links.map((link) => {
          return (
            <div>
              <a target="_blank" rel="noreferrer" href={link.route}>
                <span>{link.name}</span>
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
}
export default Links;
