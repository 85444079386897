function Experience({ experiences, title }) {
  return (
    <div id={'experience-section'} style={{ marginTop: "30px" }} className="experience-section">
      <h4 className="experience-heading">{title}</h4>
      {experiences.map((value) => {
        return (
          <div className="experience">
            <div className="experience-container">
              <div className="heading-top">
                <div>
                  <h5>{value.role}</h5>
                  <div className="experience-location left">{value.subTitle}</div>
                </div>
                <div>
                  <p>{value.date}</p>
                  <div className="experience-location right">{value.location}</div>
                </div>
              </div>

              <div className="experience-bullets">
                <ul>
                  {Array.isArray(value.bullets)
                    ? value.bullets.map((bullet) => {
                      return (
                        <>
                          <li>{bullet.val}</li>
                          <ul className="sub-bullets">
                            {Array.isArray(bullet.subBullets)
                              ? bullet.subBullets.map((subBullet) => {
                                return (
                                  <>
                                    <li>{subBullet}</li>
                                  </>
                                );
                              })
                              : null}
                          </ul>
                        </>
                      );
                    })
                    : null}
                </ul>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default Experience;
