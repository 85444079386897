import Links from "../components/LInks";
function AboutSections({ paragraphs, bullets, links }) {
  return (
    <div id={'about-section'} className="about-container">
      <div className="about-contents">
        <h4 className="cool-link">About Me</h4>
        {paragraphs.map((value) => {
          return <p className="first-section">{value}</p>;
        })}

        <p>
          <ul>
            {bullets.map((value) => {
              return <li>{value}</li>;
            })}
          </ul>
        </p>
      </div>

      <Links links={links} />
    </div>
  );
}
export default AboutSections;
